import React from "react"
import PropTypes from "prop-types"
import CircularProgress from "@material-ui/core/CircularProgress"

const Loader = ({ isLoading }) =>
  isLoading && (
    <div className="Loader">
      <CircularProgress />
    </div>
  )

Loader.propTypes = {
  isLoading: PropTypes.bool,
}

Loader.defaultProps = {
  isLoading: false,
}

export default Loader
